
// Body
$body-bg: #ffffff;
$body-color:#868789!default;

$grid-breakpoints: (
  xs: 0,
  sm: 600px,
  md: 768px,
  lg: 1024px,
  xl: 1300px
) !default;

$container-max-widths: (
  sm: 650px,
  md: 955px,
  lg: 1175px,
  xl: 1300px
) !default;

// Typography
$font-family-sans-serif:'Montserrat', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$fa-font-path:         "../../webfonts" !default;

// Colors
$blue: #2da7df;
$blue-dark: #0000cc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #e5097f;
$red: #df2d77;
$orange: #f7ba2d;
$yellow: #ffcb00;
$green: #00cc7e;
$teal: #4dc0b5;
$cyan: #2bbfd6;
$white: #ffffff;

$default:       $blue !default;
$info:          $cyan !default;
$warning:		$yellow !default;
$secondary:	#c3ccd5 !default;

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$--color-primary: $blue !default;
$--color-white: $white !default;
$--color-success: $green !default;
$--color-warning: $yellow !default;
$--color-danger: $red !default;
$--color-info: $info !default;

$navbar-light-color:		$white !default;
$navbar-dark-brand-color:	$white !default;

$carousel-control-color:            $gray-500 !default;







