.chat-wp{
	background:0 0;
	border-radius:10px;
	box-shadow:5px 5px 10px rgba(0,0,0,.1),0 0 20px rgba(0,0,0,.15);
	right:15px;
	bottom:75px;
	position:fixed;
	height:auto;
	width:300px;
	z-index:10;
}

.close-wp{
	background:$green;
	border-radius:50%;
	position:absolute;
	top:-40px;
	right:0;
	padding:4px;
	width:32px;
	height:32px;
}

.close-wp img,.header-wp img{
	margin:2px auto;
	width:20px;
	height:20px;
}

.close-wp img{
	display:block;
}

.header-wp{
	background:$green;
	border-radius:10px 10px 0 0;
	color:$white;
	text-align:center;
	padding:15px;
}

.header-wp a{
	color:$white;
	font-weight:700;
}

.header-wp a:focus,.header-wp a:hover{
	color:$white;
	text-decoration:underline;
}

.header-wp img{
	display:inline-block;
}

.container-wp{
	background:url('../img/bg-wp.jpg');
	background-size:300px;
	padding:15px;
	height:125px;
}

.user-wp{
	height:32px;
	float:left;
}

.sms-wp{
	box-shadow:2px 2px 5px rgba(0,0,0,.1);
	float:left;
	font-size:13px;
	position:relative;
	width:200px;
	height:70px;
	padding:5px 10px;
	background:#FFF;
	margin-left:20px;
	margin-top:10px;
	-webkit-border-radius:0 8px 8px 8px;
	-moz-border-radius:0 8px 8px;
	border-radius:0 8px 8px;
}

.sms-wp:after{
	content:'';
	position:absolute;
	border-style:solid;
	border-width:0 15px 15px 0;
	border-color:transparent #FFF;
	display:block;
	width:0;
	z-index:1;
	left:-15px;
	top:0;
}

.footer-wp{
	background:#f5f1ee;
	border-radius:0 0 10px 10px;
	padding:10px 15px;
	height:50px;
	width:100%;
}

.footer-wp input,.footer-wp input:focus{
	background-color:$white;
	border-color:$white;
	border-radius:5px;
	border-style:solid;
	border-width:1px;
	font-size:13px;
	padding:5px;
	width:170px;
}

.footer-wp button{
	background:$green;
	border:0 solid transparent;
	border-radius:6px;
	color:$white;
	display:inline-block;
	font-size:15px;
	margin-left:5px;
	padding:4px 10px;
}

.footer-wp button:focus,.footer-wp button:hover{
	background:darken($green, 10%);
}

.footer-wp img{
	display:inline-block;
	margin:-2px 2px 0;
	width:15px;
	height:15px;
}

.wp-xs{
	bottom:10px;
	display:none;
	position:fixed;
	background:$green;
	border:0 solid transparent;
	border-radius:50%;
	color:$white;
	padding:10px;
	width:45px;
	height:45px;
	z-index:10;
}

.wp-what{
	right:10px;
}

.wp-call{
	right:65px;
}

.wp-xs:focus,.wp-xs:hover{
	background:darken($green, 10%);
}

.wp-xs img{
	display:block;
	margin:0;
	width:24px;
	height:24px;
}

@media (max-width:992px){
	.chat-wp{
		display:none;
	}

	.wp-xs{
		display:block;
	}
}