/* Portfolio Filter Stylesheet */



/*****Basic Layout*****/

ul#filter { 
	padding: 0px;
	text-align: center;
	list-style: none;  
	width: 100%; 
	font-size: 17px;
}

ul#filter li { 
	border-right: 1px solid #dedede;
	display: inline-block;
	float: none;
	line-height: 30px;
	margin-right: 10px;
	margin-bottom: 10px; 
	padding-right: 10px;
	text-align: center;
}

ul#filter li:last-child { 
	border-right: none; 
	margin-right: 0; 
	padding-right: 0; 
}

ul#filter a { 
	text-decoration: none; 
}

ul#filter li.current a, 
ul#filter a:hover { 
	text-decoration: none; 
}

ul#filter li.current a { 
	font-weight: bold; }

ul#portfolio { 
	list-style: none; 
}

ul#portfolio li { 

}

ul#portfolio a { 
	display: block; 
	width: 100%; 
}

ul#portfolio a:hover { 
	text-decoration: none; 
}

ul#portfolio img { 

}

@media (max-width: 768px) {

	ul#filter {
		margin: 0;
		padding: 0;
		text-align: center;
		li, li:last-child {
			border-right: 0 solid #dedede;
			margin-right:0;
			.small{
				display:none;
			}
		}
		a{
			font-size: 12px;
			.btn{
				padding:0.25rem !important;
			}
			img{
				width:28px;
			}
		}
	}
}
