
.logo-login{
	width: 75%; 
	max-width: 400px;
}

.navbar-footer{
	text-align: center;
	padding: 10px;
}


.radio input[type="radio"], .radio-inline input[type="radio"], .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"]{
	position: relative !important;
	margin: 10px 0 0 0 !important;	
}