.error-img{
  display:block; 
  margin:0 auto; 
  max-width:600px; 
  width:100%;
}

.terminal-header{
  background:#c9c9c9; 
  border-radius:6px 6px 0 0; 
  display:inline-block;
  width:100%; 
  div{
    border-radius: 50%; 
    display:inline-block; 
    height:15px;
    width: 15px;
    &:nth-child(1){
      background: #be2026;
      margin: 8px 5px 8px 10px;
    }
    &:nth-child(2){
      background: #e7a423; 
      margin: 8px 5px;
    }

    &:nth-child(3){
      background: #3aae49; 
      margin: 8px 5px;
    }
  }
}

.terminal-cont{
  background:#40413d; 
  border-radius:0  0 3px 3px;
  display:inline-block;
  width:100%; 
  h3{
    color:#fff; 
    font-size:20px; 
    text-align:center; 
    letter-spacing:1px;
  }
  a{
    margin:0 auto 25px; 
    width: 150px;
  }
  p{
    color: lime !important; 
    font-family: "Courier";
    font-size: 15px;
    line-height: 16px;
    margin:25px 25px 10px 25px;
    white-space: nowrap;
    overflow: hidden;
    width: 30em;
    animation: type 4s steps(60, end); 
  }
  .line-2{
    animation: type2 7s steps(60, end);
    margin:5px 25px 10px 25px;
  }
  .line-3{
    animation: type2 13s steps(60, end);
    margin:5px 25px 25px 25px;
  }
  span{
    animation: blink 1s infinite;
  }
}

@keyframes type{ 
  from { width: 0; } 
} 

@keyframes type2{
  0%{width: 0;}
  50%{width: 0;}
  100%{ width: 100; } 
} 

@keyframes blink{
  to{opacity: .0;}
}

@media (max-width: 1200px) {
  .terminal-cont p{
    font-size: 13px;
    width: 355px;
  }
}

@media (max-width: 992px) {
  .terminal-cont{
      p {
      animation: none;
      clear: both;
      display: inline-block;
      font-size: 10px;
      line-height: 12px;
      margin: 4px 2%;
      text-align: center;
      width: 96%;
    }
    .line-2{
      animation: none;
      margin:4px 2%;
      width: 96%;
    }
    .line-3{
      animation: none;
      margin:4px 2%;
      width: 96%;
    }
  } 
}