.navbar-atsys{
	background: $blue !important;
	border-radius: 0px;
	border: 0px;
	margin-bottom:0px;
	margin-top:-5px;
	font-size: 1.2rem;
	letter-spacing: 0.75px;
	img{
		height: 95px;
	}
	li{
		> a{
			color: $white !important;
			padding:20px 15px !important;
			&:focus, &:hover {
				background-color: lighten($blue, 10%);
			}
			&.bg-light, &.text-dark{
				background-color: lighten($blue, 10%) !important;
				color: $white !important;
				&:focus, &:hover, &:focus{
					background-color: lighten($blue, 10%) !important;
					color: $white !important;
				}
			}
		}	
		> ul{
			font-size: 18px;
		}
	} 
	.dropdown-menu{
		background-color:lighten($blue, 10%);
		border-bottom: 5px solid darken($blue,5%);
		color:$white;
		padding: 0;
		> li > a, .highlighted {
			border-bottom:1px solid darken($blue,5%);
			color:$white;
			padding: 10px 30px 10px 20px!important;
			&:focus, &:hover {
				background-color: darken($blue,5%);
			}
		}
		.sub-arrow{
			margin-right: 0.75em;
		}
	}
	&.navbar-dark{
		.navbar-toggler {
			color: rgba($white, 0.5);
			border-color: rgba($white, 0.50);
			border-width: 2px;
			background:rgba($cyan,0.6);
			&:hover,&:focus,&:active{
				background:rgba($cyan,1.0);
			}
		}
		.navbar-toggler-icon {
			background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
		}
	}
}

.header-atsys-home{
	background:rgba($blue,.8) !important;
	position: relative;
	z-index: 1;
	.navbar-atsys{
		background: transparent !important;
	}
}



/*////////// //////////*/

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: #34a6da;
  border-color: #40b9e9;
}
@include media-breakpoint-down(md) {
	.navbar-atsys{
		img{
			height: 85px;
		}
		li {
			> a {
				border-bottom: 2px solid rgba($white, 0.50);
				padding: 15px !important;
				.sub-arrow{
					border: 2px solid rgba($white, 1) !important;
					line-height: 1.4em !important;
				}
			}
			.dropdown-menu{
				border-radius:0;
				margin-top:0;
			}
		}
	}
}

@include media-breakpoint-down(sm) {
	.navbar-atsys{
		img{
			height: 75px;
		}
	}
}