.mt--100{
	margin-top: -100px;
}

.mb--100{
	margin-bottom: -100px;
}

.mt--75{
	margin-top: -75px;
}

.mb--75{
	margin-bottom: -75px;
}

.mt--50{
	margin-top: -50px;
}

.mb--50{
	margin-bottom: -50px;
}

.relative{
	position:relative;
	z-index: 1;
}

a{
	&:hover, &:focus, &:active{
		text-decoration:none;
	}
}

b, strong {
	font-weight: bold;
}

p, .p{
	color: $gray-700;
	font-size: 1.1rem;
	font-weight:lighter;
	line-height: 1.5;
}

.no-animate{
	transition: none !important;
	&:hover,&:focus{
		transform: initial !important;
	}
}

.btn{
	transition: all .2s ease-out 0s;
	&:hover,&:focus{
		transform:translate(5px);
	}
}

.btn-outline-light{
	color:$white !important;
	&:hover, &:focus{
		color:$blue !important;
		img{filter:invert(50%);}
	}
}

.btn-light{
	color:$blue !important;
	&:hover, &:focus{
		color:$blue !important;
	}
}

.btn-dark{
	color:$white !important;
	background-color:$blue-dark;
	border-color:$blue-dark;
	&:hover, &:focus{
		background-color:lighten($blue-dark, 5%);
		border-color:lighten($blue-dark, 5%);
	}
}

.btn-warning{
	color:$white !important;
	&:hover, &:focus{
		color:$white !important;
	}
}

.text-blue-dark{
	color:$blue-dark !important;
}

@each $color, $value in $colors {
  .bg-#{$color} {
    background-color: $value;
  }
}

@each $color, $value in $colors {
  .bg-#{$color}-darken {
    background-color: darken($value, 15%);
  }
}

@each $color, $value in $colors {
  .bg-#{$color}-light {
    background-color: lighten($value, 15%);
  }
}

.title{
	hr {
		border: 3px solid $gray-300;
		width: 100px;
	}
}

.bg-primary{
	.list-arrow{
		li{
			color:$white;
			&::before {
			  color: lighten($blue,30%);
			}
		}
	}
}

.bg-blue-dark{
	background:$blue-dark;
}

.bg-middle{
	background:$blue url('../../images/bg-middle.png');
	background-attachment: fixed;
	background-position: center;
	background-size: auto 500px;
}

.header-home{
	height: 555px;
}

.home-video{
	left: 0;
	max-height:calc(650px - 5px);
	overflow:hidden;
	position: absolute;
	top: 0;
	z-index: 0;
}

.navigator{
	padding: 30px 0 15px;
	background: #ececec;
	box-shadow: 5px 5px 20px rgba(0,0,0,0.2);
	border-radius: 5px;
	position:relative;
	.btn-red, .btn-orange, .btn-green{
		border-radius:50%;
		position:absolute;
		top:10px;
		display: block;
		height:10px;
		width:10px;
	}
	.btn-red{
		background:$red;
		left:5px;
	}
	.btn-orange{
		background:$orange;
		left:20px;
	}
	.btn-green{
		background:$green;
		left:35px;
	}
}


.header-title{
	padding-bottom: 40px;
	padding-top: 40px;
	h1{
		color:$white; 
		font-family:'Montserrat', sans-serif; 
		font-weight:600; 
		letter-spacing:2px; 
		font-size:32px; 
		margin-top: 40px;
		text-align: center;
	}
}

.header-atsys{
	background:$blue;
	h1{
		font-size: 38px;
	}
}

.header-title h2,
.slide-txt h2{
	color:$white; 
	font-family:'Montserrat', sans-serif;
	font-size:22px;
	font-weight:normal; 
	letter-spacing:2px;
	text-align: center;
}

ul#portfolio{
	li{
		position:relative;
		a{
			padding:10px;
		}
		.img-work{
			border-radius:3px;
			box-shadow:5px 5px 10px rgba(0,0,0,0.20), -0px -0px 20px rgba(0,0,0,0.10);
			transition: all .2s ease-out 0s;
			&:hover{
				box-shadow:5px 5px 15px rgba(0,0,0,0.10), -0px -0px 25px rgba(0,0,0,0.05);
				-ms-transform: scale(0.99);
				-webkit-transform: scale(0.99);
				transform: scale(0.99);
			}
		}
	}
}

.card-title, .card-link, .btn-outline-light{
	transition: all .2s ease-out 0s;
	&:hover,&:focus{
		transform:translate(5px);
		img{filter:invert(50%);}
	}
}

.app-title h1, .app-title h2{ text-align: left !important;}

.header-slider{ max-height: 400px }

.header-slider > .flexslider { top: 40px; }

.slide-txt h1{
	color:$white; 
	font-family:'Montserrat', sans-serif; 
	font-weight:800; 
	letter-spacing:2px; 
	font-size:55px; 
	margin-top: 50px;
}

.slide-txt h2{
	text-align: left !important;
}

.slide-txt h4{
	color:$white; 
	font-weight:normal; 
	letter-spacing:1px; 
	font-size:24px;
}

.slide-txt p {
	color: $white;
	font-size: 1rem;
	letter-spacing: 0.5px;
	font-weight: normal;
}

.container-atsys{
	padding-bottom:100px;
	padding-top:50px;
	h2{
		color: $blue;
		font-weight: 600;
		margin-top: 20px;
		line-height: 40px;
		>span{
			color: #5ec4ec;
		}
	}
	h3{
		color: $blue;
		font-weight: 300;
		margin-top: 20px;
	}
	h4{
		color: #5ec4ec;
		margin-top: 20px;
		margin-bottom: 10px;
	}
	h5{
		color: #38a5e1;
		font-size: 15px;
		font-weight: bold;
		line-height: 1.5;
	}
	p{
		color: $gray-700;
		font-size: 1.1rem;
		font-weight:lighter;
		line-height: 1.5;
		text-align: justify;
		-webkit-hyphens: auto;
		-moz-hyphens: auto;
		-ms-hyphens: auto;
		hyphens: auto;
	}
	li{
		color: $gray-700;
		font-size: 1.1rem;
		font-weight:lighter;
	}
}

.footer-curve{
	bottom:-1px; 
	left:1px;
	position: absolute; 
	z-index: 0;
}


.list-check{
	list-style: none; padding-left: 21px;
	li{
		margin: 10px 0;
		color: $gray-700;
		font-size: 1.1rem;
		font-weight:lighter;
		line-height: 1.5;
		&::before {
		  color: $blue;
		  content: "\f00c";
		  font-family: "Font Awesome 5 Free";
		  font-weight:bold;
		  margin-left: -21px;
		  margin-right: 5px;
		}
	}
}

.list-arrow{
	list-style: none; padding-left: 21px;
	li{
		margin: 10px 0;
		color: $gray-700;
		font-size: 1.1rem;
		font-weight:lighter;
		line-height: 1.5;
		&::before {
		  color: $blue;
		  content: "\f105";
		  font-family: "Font Awesome 5 Free";
		  font-weight:bold;
		  margin-left: -10px;
		  margin-right: 5px;
		}
	}
}

.portfolio-desc{
	ul{
		list-style: none; padding-left: 21px;
		li{
			margin: 10px 0;
			&::before {
			  color:$blue;
			  content: "\f105";
			  font-family: "Font Awesome 5 Free";
			  font-weight:bold;
			  margin-left: -10px;
			  margin-right: 5px;
			}
		}
	}	
}



.text-col-2{
	-webkit-column-count: 2;
	-moz-column-count: 2;
	column-count: 2;
	-webkit-column-gap: 50px;
	-moz-column-gap: 50px;
	column-gap: 50px;
}

.col-right-atsys ul,
.post ul{
	color: #818d93;
	font-size: 15px;
	line-height: 1.5;
	list-style: none; 
	padding-left: 21px;
}

.col-right-atsys li::before,
.post li::before {
  color:lighten($blue,30%);
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight:bold;
  font-size: 18px;
  margin-left: -18px;
  margin-right: 10px;
}

.col-right-atsys li{
	margin: 5px 0;
}


.services{ 
	margin-bottom: 100px;
	margin-top: 125px;
	a:hover, a:focus{text-decoration:none;}
	.card{
		min-height: 100%;
		.card-header{
			border-bottom:3px solid darken($blue,10%);
		}
	}
	h2{
		color:$blue;
		font-weight:100;
		text-align:center;
	}
	p{
		color:$black; 
		font-weight:100; 
		text-align:center; 
		font-size:18px;
	}
	img{ 
		display:block;
		margin:25px auto;
		-webkit-transition: all .2s ease-in-out;
		-moz-transition: all .2s ease-in-out;
		-o-transition: all .2s ease-in-out;
		transition: all .2s ease-in-out;
		opacity: 1.0;
		width:150px; 
		&:hover{ 
			transform:translate(0,-25px);
		}
	}
}

.services-2{
	margin-top: 50px;
}

.title-bg-blue{
	padding-top:45px; 
	padding-bottom:45px;
	h2{
		color:$white;
		text-align:	center; 
		text-transform:uppercase; 
		letter-spacing:2px; 
		font-weight:600;
	}
	hr{
		border:3px solid lighten($blue,30%);
		width:100px;
	}
}


.recents-works h2 {
  color: #bebdbd;
  font-weight: 600;
  letter-spacing: 2px;
  text-align: center;
  text-transform: uppercase;
}

.recents-works hr{
	border:3px solid #5ec4ec;
	margin-bottom: 35px;
	width:100px;
}

.comment-txt{
	padding-top:45px;
	padding-bottom:45px;
	h2{
		color:lighten($blue, 35%);
		font-weight:900;
	}
	h3{
		color:$white; 
		text-align: left;
		font-weight:500; 
		margin-top: 0; 
		letter-spacing: 0.5px; 
		line-height: 35px;
	}
	i{
		color:$yellow;
		font-size:20px;
	}
	.list-check{
		li{
			&::before {
			  color: lighten($blue, 35%);
			}
		}
	}
}

.comment-img{
	background-size:100% auto !important; 
	background-position: center bottom !important; 
	height:350px;
}

.comment-pravela{
	background:url('../img/comment-pravela.png') no-repeat; 
}

.comment-thelight{
	background:url('../img/comment-thelight.png') no-repeat; 
}

.comment-ecocolor{
	background:url('../img/comment-ecocolor.png') no-repeat; 
}

.comment-zumba{
	background:url('../img/comment-zumba.png') no-repeat; 
}


.header-app-img{
	background:url('../../images/app2.png') no-repeat; 
	background-size:100% auto; 
	background-position: center bottom; 
	height:400px;
}

.team-title{
	margin-top:75px;
	h2{
		color:#36A7DA; 
		text-align: center; 
		text-transform:uppercase; 
		letter-spacing:2px; 
		font-weight:600;
	}
	h4{
		color:#bebdbd;
		text-align: center; 
		letter-spacing:0.5px; 
		font-weight:100;
	}
	hr{
		border:3px solid #5EC4EC;
		width:100px;
	}
}


.team{
	a:hover,:focus{ text-decoration:none; }
	.card{
		min-height: 100%;	
		img{
			display:block; 
			margin:-40px auto 0px; 
			width:175px; 
			border-radius:50%;
			-webkit-transition: all 0.5s ease-in-out;
			-moz-transition: all 0.5s ease-in-out;
			-o-transition: all 0.5s ease-in-out;
			transition: all 0.5s ease-in-out;
			position: relative;
			left:0;
			z-index: 2;
			&:hover{
				left:5px;
			}
		}
		h2{
			color:$blue; 
			font-weight:100; 
			text-align:center;
		}
		h5{
			color:#bebdbd; 
			font-weight:100; 
			text-align:center;
		}

		p{
			color:#818d93; 
			font-weight:100;
			text-align:center; 
			font-size:16px;
			-moz-hyphens:auto;
			-ms-hyphens:auto;
			-webkit-hyphens:auto;
			-o-hyphens:auto;
			hyphens:auto;
		}
	}
}




.team-page-photo{
	/*border-radius:50%; 
	box-shadow: 0px 8px 8px rgba(13,90,141,0.65); */
	margin:50px 0 -35px 0;
	max-width: 190px;
	top: 20px;
	position: relative;
	width:100%; 
}

.recent-post{
	background-color: $white; 
	border-radius: 3px; 
	box-shadow: 0px 2px 6px rgba(178,178,178,0.75); 
	margin:25px 0; 
	text-align: center;
}

.recent-post-photo{
	background-position: center;
	background-size: cover;
	display: block;
	height: 200px;
	margin: 0px auto;
	width: 100;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
	opacity: 1.0;
}

.recent-post-photo:hover{
	opacity: 0.75;
}

.recent-post h3{
	color: #40b9e9;
	margin:10px 0 10px 10px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.recent-post p{
	color:#979797;
	margin:5px 0;
}

.feature-atsys{
	padding-left:50px; 
	margin-top:25px; 
	margin-bottom:35px; 
	width: 85%;
	h3{
		text-align:left; 
		color:#209DD5; 
		font-size:45px; 
		line-height:65px;
		> span{
			color:#5EC4EC;
		}
	}
	p{
		color:#5EC4EC;
		text-align:right;
	}
}

.services-marketing{
	border-bottom: 3px solid #ececed;
	padding-bottom: 25px;
	padding-top:25px;
}

.services-marketing img{
	display:block; 
	margin:0 auto; 
	margin-top:8%;  
	max-width:507px; 
	width:100%;
}

.contact-footer{
text-align: center;
}

.contact-footer h2{
  color: $blue;
  font-style: italic;
  font-weight: 600;
  margin: 35px 0;
}

.btn-contact-footer{
	margin: 15px;
}

.btn-contact-footer i{
	font-size: 40px; 
	float: left; 
	margin-right:10px;
}

.btn-contact-footer span{
	float:left;
	text-align:left;
}

.breadcrumb{
	background-color:transparent;
}

.footer-menu {
	ul{
		list-style: none;
		padding:0;
		li{
			margin: 10px 0;
			padding-left:15px;
			letter-spacing: 0.5px;
			&::before {
			  color:lighten($blue,30%);
			  content: "\f105";
			  font-family: "Font Awesome 5 Free";
			  font-weight:bold;
			  margin-left: -15px;
			  margin-right: 5px;
			}
			a{ 
				color: $white;
				&:hover,
				&:focus,
				&:active{ 
					color:lighten($blue,30%);
					text-decoration: none;
				}
			}
		}
	}
}

.footer-atsys{
	background:darken($blue, 10%);
	color:$white; 
	text-align:center; 
	font-weight:100; 
	letter-spacing: 0.5px;
	a{	
		font-weight: bold;
		&:hover, &:focus, &:active {
			color:$info;
			text-decoration:none;
		}
	}
}

.btn-works{
	border-radius: 0px;
	background:#36A7DA;
	border:1px solid $white;
	color: $white;
	font-family: 'Lato' sans-serif;
	font-size: 16px;
	font-weight: 600;
	margin-top: 30px;
	margin-bottom:30px;
	padding: 10px;
	&:hover, &:focus, &:active {
		border-radius: 0px;
		background:#5EC4EC;
		border:1px solid $white;
		color: $white;
		font-family: 'Lato' sans-serif;
		font-size: 16px;
		font-weight: 600;
		margin-top: 30px;
		margin-bottom:30px;
		padding: 10px;
	}
}


.btn-more-works{
	background:#5EC4EC;
	border-radius:0px; 
	border:0px; 
}

.btn-more-works:hover, .btn-more-works:focus, .btn-more-works:active{
	background:#36A7DA;
	border-radius:0px; 
	border:0px; 
}

.capital-letter {
  background: white none repeat scroll 0 0;
  color: $blue;
  float: left;
  font-size: 45px;
  line-height: 40px;
  margin-right: 5px;
  padding: 2px;
}

/*//////////////  B L O G  //////////////*/

.post{
	border-bottom: 1px solid #5EC4EC;
	margin-bottom: 40px;
	padding-bottom: 20px;
	small{
		color:#999;
	}
	img {
		display:block; 
		margin:30px auto;
		height: auto !important;
		max-width:100%; 
		width:auto !important;
	}
	a{ margin: 20px 0;}
	.list-group{
		li{
			a{
				font-weight:500;
			}
		}
	}
}

.instagram{
	-moz-border-bottom-colors: none;
	-moz-border-left-colors: none;
	-moz-border-right-colors: none;
	-moz-border-top-colors: none;
	border-left-color: #1b809e;
	border-color: #eee;
	border-image: none;
	border-radius: 3px;
	border-style: solid;
	border-width: 1px 1px 1px 5px;
	margin: 20px 0;
	padding: 0px;
}

/*//////////////  E N D  B L O G  //////////////*/

/*//////////////  C O N T A C T O  //////////////*/

label{
	color: $blue;
	font-size:1rem;
	letter-spacing:0.5px;
}

.form-control {
  background-color: #f1f7f9;
  background-image: none;
  border: 2px solid #5EC4EC;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.0) inset;
  color: #555;
  display: block;
  font-size: 1.0rem;
  height: 40px;
  line-height: 1.42857;
  padding: 6px 12px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 100%;
}

label a{ text-decoration: underline;}

/*//////////////  E N D  C O N T A C T O  //////////////*/

/*///////////  M A N T E N I M I E N T O  W E B  ///////////*/

.mant-comment{
	display:inline-block; 
	margin-left: -55px; 
	margin-bottom:30px; 
	width:90%;
}

.mant-love{
	display: inline-block; 
	width: 80px; 
	vertical-align: top;
}

.mant-nav{
	background: url('../../images/navegador.png') no-repeat;
	background-size: 100% auto;
	background-position: center top;
}

.mant-nav img{
	display: block;
	margin: 30px auto;
	max-width: 500px;
	width: 100%;
}

.clients-container{
	padding-bottom: 25px;
	padding-top: 25px;
}

.logos-title {
  color: #a6a6a6;
  font-family: "Lato",sans-serif;
  font-size: 30px;
  font-weight: 300;
  letter-spacing: 0.5px;
  margin-bottom: 20px;
  text-align: center;
}

.logos-mant{
	display: block;
	margin: 25px auto;
	max-width: 300px;
	opacity: 0.5;
	width: 100%;
}

/*///////////  E N D  M A N T E N I M I E N T O  W E B  ///////////*/

/*///////////  L O G I N  C L I E N T S  ///////////*/

.card-container.card {
    max-width: 350px;
    padding: 30px;
}

.btn-login {
  display: block;
  margin: 20px auto;
  width: 175px;
}


/*
 * Form styles
 */
.profile-name-card {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin: 10px 0 0;
    min-height: 1em;
}

.reauth-email {
    display: block;
    color: #FADCDE;
    line-height: 2;
    margin-bottom: 10px;
    font-size: 14px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.form-signin #inputEmail,
.form-signin #inputPassword {
    direction: ltr;
    height: 44px;
    font-size: 16px;
}

.form-signin input[type=email],
.form-signin input[type=password],
.form-signin input[type=text],
.form-signin button {
    width: 100%;
    display: block;
    margin-bottom: 10px;
    z-index: 1;
    position: relative;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.form-signin .form-control:focus {
    /*border-color: rgb(104, 145, 162);
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgb(104, 145, 162);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgb(104, 145, 162);*/
}

.form-signin label{
  color: #d9dadb;
}

.forgot-password {
    color: #d9dadb;
    text-align: center;
}

.forgot-password a{
    color: $white;
    text-align: center;
}

.forgot-password:hover,
.forgot-password:active,
.forgot-password:focus{
    color: #d9dadb;
}

footer p{ text-align: center; color: $white;}
footer p a{color: $white;}

.footer-guiasex img {
  display: block;
  margin: 20px auto 5px;
}

/*///////////  E N D  L O G I N  C L I E N T S  ///////////*/

@include media-breakpoint-down(lg) {
	.header-home{
		height: 525px;
	}
	.home-video{
		height:calc(619px - 5px);
	}
	.slide-txt h1{ 
		font-size:45px; 
		margin-top: 0px;
	}
	.slide-txt p {
		letter-spacing: 0;
	}
}



@include media-breakpoint-down(md) {

	.mt--50{
		margin-top: -15px;
	}

	.mb--50{
		margin-bottom: -15px;
	}

	.header-home{
		height: 510px;
	}

	.home-video{
		height:calc(600px - 10px);
	}

	.header-atsys{
		h1{font-size:26px;}
	}

	.comment-img{
		background-size:100% auto !important; 
		background-position: center bottom !important; 
		height:350px;
	}

	.comment-zumba{
		background:url('../img/comment-zumba-mobile.png') no-repeat; 
	}

	.container-footer{
		background: url('../img/bg.jpg') no-repeat center top; 
	}

	.app-title h1, .app-title h2{ text-align: center !important;}

	.header-app-img{
		background:url('../../images/app2.png') no-repeat; 
		background-size:auto auto; 
		background-position: center bottom; 
		height:300px;
	}

	.slide-txt h1{
		letter-spacing:2px; 
		font-size:30px; 
		margin-top: 30px;
		text-align: center;
	}

	.slide-txt h4{
		color:$white; 
		font-weight:100; 
		letter-spacing:1px; 
		font-size:24px;
		text-align: center;
	}

	.slide-txt p{
		color:$white; 
		font-size:15px; 
		letter-spacing:0.3px;
		margin-bottom: 20px;
		text-align: center;
	}

	.header-slider{ max-height: 300px }

	.header-slider > .flexslider { top: 20px; }

	.header-slider > .flexslider img{
		display: block !important;
		margin: 0px auto !important;
		max-width: 400px !important;
	}

	.services{ 
		margin-bottom: 100px;
		margin-top: 100px;
		img{ 
			display:block;
			margin:25px auto;
			width:100px; 
		}
	}

	.services-2{
		margin-top: 0px;
	}


	.text-col-2{
		-webkit-column-count: 1;
		-moz-column-count: 1;
		column-count: 1;
		-webkit-column-gap: 0px;
		-moz-column-gap: 0px;
		column-gap: 0px;
	}

	.team-page-photo{
		border-radius:50%; 
		box-shadow: 0px 8px 8px rgba(13,90,141,0.65); 
		display: block;
		margin:15px auto; 
		position: inherit;
		max-width: 155px;
		width:100%; 
	}

	.slide-txt > .team-page-h1,
	.slide-txt > .team-page-h2,
	.slide-txt > .team-page-h4{
		text-align: center !important;
	}

	.btn-contact-footer{
		margin: 15px 0;
		text-align: center;
		width: 100%
	}

	.btn-contact-footer i{
		font-size: 30px; 
		float: none; 
		margin-right:10px;
		display: block;
	}

	.btn-contact-footer span{
		float:none;
		text-align:center;
		display: block;
	}

	/*///////////  M A N T E N I M I E N T O  W E B  ///////////*/

	.mant-comment{
		display:inline-block; 
		margin-left: -10px; 
		margin-bottom:30px; 
		width:80%;
	}

	.mant-love{
		display: inline-block; 
		width: 80px; 
		vertical-align: top;
	}

	.mant-nav{
		background: url('../../images/navegador.png') no-repeat;
		background-size: auto 100%;
		background-position: center top;
	}

	.mant-nav img{
		display: block;
		margin: 30px auto;
		max-width: 500px;
		padding: 40px;
		width: 100%;
	}

	/*///////////  E N D  M A N T E N I M I E N T O  W E B  ///////////*/

}

@include media-breakpoint-down(sm) {

	.mt--100{
		margin-top: -35px;
	}

	.mb--100{
		margin-bottom: -35px;
	}

	.header-home{
		height: 520px;
	}

	.header-atsys{
		h1{font-size:24px;}
		h2{font-size:20px;}
	}

	.header-app-img{
		background:url('../../images/app-mobile.png') no-repeat; 
		background-size: auto; 
		background-position: center bottom; 
		height:250px;
	}

	.slide-txt h1{
		color:$white; 
		font-family:'Montserrat', sans-serif; 
		font-weight:800; 
		letter-spacing:2px; 
		font-size:30px; 
		margin-top: 30px;
		text-align: center;
	}

	.slide-txt h4{
		color:$white; 
		font-weight:100; 
		letter-spacing:1px; 
		font-size:24px;
		text-align: center;
	}

	.slide-txt p{
		color:$white; 
		font-size:15px; 
		letter-spacing:0.3px;
		margin-bottom: 20px;
		text-align: center;
	}

	.header-slider{ max-height: 300px }

	.header-slider > .flexslider { top: 20px; }

	.header-slider > .flexslider img { 
		display: block;
		margin: 0px auto;
		max-width: 400px; 
	}

	.services{ 
		margin-bottom: 100px;
		margin-top: 150px;
	}

	.comment-txt{
		padding-top:45px;
		padding-bottom:0px;
	}

	.card-comment img{
		max-width: 150px;
	}

	.comment-img{
		background-size:100% auto !important; 
		background-position: center bottom !important; 
		height:300px;
	}

	.comment-pravela{
		background:url('../img/comment-pravela-mobile.png') no-repeat; 
	}

	.comment-thelight{
		background:url('../img/comment-thelight-mobile.png') no-repeat; 
	}

	.comment-zumba{
		background:url('../img/comment-zumba-mobile.png') no-repeat; 
	}

	.feature-atsys{
		padding-left:25px; 
		margin-top:25px; 
		margin-bottom:25px; 
		width: 90%;
		h3{
			font-size:22px; 
			line-height:30px;
		}
	}

	.recents-works h2 {
		font-size: 1.0rem;
	}

	.contact-footer h2 {
		font-size:1.3rem;
	}

	.btn-contact-footer{
		margin: 15px 0;
		text-align: center;
		width: 100%
	}

	.btn-contact-footer i{
		font-size: 40px; 
		float: none; 
		margin-right:10px;
		display: block;
	}

	.btn-contact-footer span{
		float:none;
		text-align:center;
		display: block;
	}

	.team{
		.card{
			img{
				margin:-40px auto 0px; 
				width:125px; 
			}
		}
	}

	/*///////////  M A N T E N I M I E N T O  W E B  ///////////*/

	.mant-comment{
		display:inline-block; 
		margin-left: 0px; 
		margin-bottom:30px; 
		width:80%;
	}

	.mant-love{
		display: inline-block; 
		width: 50px; 
		vertical-align: top;
	}

	.mant-nav{
		background: url('../../images/navegador.png') no-repeat;
		background-size: auto 100%;
		background-position: center top;
	}

	.mant-nav img{
		display: block;
		margin: 30px auto;
		max-width: 500px;
		padding: 40px;
		width: 100%;
	}

	/*///////////  E N D  M A N T E N I M I E N T O  W E B  ///////////*/

}

@include media-breakpoint-down(xs) {

	.header-slider{ max-height: 205px; }

	.header-slider > .flexslider { top: 20px; }

	.header-slider > .flexslider img{ 
		max-width: 285px !important;
	}

	.header-app-img{
		background:url('../../images/app-mobile.png') no-repeat; 
		background-size: auto auto; 
		background-position: center bottom; 
		height:175px;
	}

	.comment-img{
		background-size:100% auto; 
		background-position: center bottom; 
		height:185px;
	}

	.comment-pravela{
		background:url('../img/comment-pravela-mobile.png') no-repeat; 
	}

	.comment-thelight{
		background:url('../img/comment-thelight-mobile.png') no-repeat; 
	}

}